<template>
    <v-container class="ma-0 pa-0">
        <v-row class="py-10 px-8">
            <strong>Filtros</strong>

            <div class="mt-4">
                Fecha 
                <v-menu v-model="menuFrom" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="calendar.dateFrom" label="Desde" clearable prepend-icon="mdi-calendar-today" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="calendar.dateFrom" @input="menuFrom = false"></v-date-picker>
                </v-menu>
                <v-menu v-model="menuTo" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px" >
                    <template v-slot:activator="{ on }">
                        <v-text-field @keydown.enter="filter()" v-model="calendar.dateTo" label="Hasta" clearable prepend-icon="mdi-calendar" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="primary" v-model="calendar.dateTo" @input="menuTo = false"></v-date-picker>
                </v-menu>
            </div>

            <v-select @keydown.enter="filter()" v-model="calendar.company_id" :items="companyLists" item-text="name" item-value="id" attach chips label="Empresa" multiple>
            </v-select>
            <v-select @keydown.enter="filter()" v-model="calendar.contact_id" :items="contactLists" item-text="name" item-value="id" attach chips label="Contacto" multiple>
            </v-select>
            <v-select @keydown.enter="filter()" v-model="calendar.activity_id" :items="activityLists" item-text="type" item-value="id" attach chips label="Actividad" multiple>
            </v-select>
            <v-select @keydown.enter="filter()" v-model="calendar.user_id" :items="userLists" item-text="name" item-value="id" attach chips label="Ejecutivo" multiple>
            </v-select>

            <v-text-field @keydown.enter="filter()" v-model="calendar.description" label="Descripción"></v-text-field>

        </v-row>
        <v-card style="position:absolute; bottom:0; width:100%;" tile color="primary">
            <v-list-item link @click="filter()" dark>
                <v-list-item-content>
                    <strong>Filtrar (0)</strong>
                </v-list-item-content>
            </v-list-item>    
        </v-card>
    </v-container>
</template>
<script>
  export default {
    data: () => ({
        menuTo:false,
        menuFrom:false,
        calendar:{
            company_id:'',
            contact_id:'',
            activity_id:'',
            dateFrom:'',
            dateTo:'',
            description:'',
            completed:'',
            user_id:''
        },
    }),
    computed: {
        companyLists(){
            return this.$store.state.company.companies;
        },
        contactLists(){
            return this.$store.state.contact.contacts;
        },
        activityLists(){
            return this.$store.state.activity.activities;
        },  
        userLists(){
            return this.$store.state.user.users;
        }, 
    },
    created () {
        this.$store.dispatch('user/getUsers')
        this.$store.dispatch('company/getCompanies')
        this.$store.dispatch('contact/getContacts')
        this.$store.dispatch('activity/getActivities')
    },
    methods: {
        filter(){
            this.$emit("filtersCalendar", this.calendar);
        }
    }
  }
</script>