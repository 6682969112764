<template>
    <v-container style="max-width:100vw;">
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterClients @filtersCalendar="filtersCalendar"/>
        </v-navigation-drawer>
        <!-- Contenedor -->
        <v-data-table :headers="headers" :items="calendars" sort-by="date" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Actividades</v-toolbar-title>
                    <v-btn icon>
                        <v-icon @click.stop="filters = !filters">mdi-filter</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left v-if="permissions('editActivities')==true || permissions('deleteActivities')==true">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item)" v-show="permissions('editActivities')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)" v-show="permissions('deleteActivities')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- Actividad -->
            <template v-slot:[`item.activity`]="{ item }">
                <v-chip outlined class="pa-2" small :color="color(item.activity)">{{item.activity}}</v-chip>
            </template>
            <!-- Estatus -->
            <template v-slot:[`item.completed`]="{ item }">
                <v-icon @click="status(item.id,1)" v-if="item.date<=new Date() && item.completed==0" color="red"> mdi-close </v-icon>
                <v-icon @click="status(item.id,0)" v-else-if="(item.completed==1)" color="green"> mdi-check </v-icon>
                <v-icon @click="status(item.id,1)" v-else color="primary">  mdi-calendar-clock</v-icon>
            </template >
            <!-- Creación -->
            <template v-slot:[`item.created_at`]="{ item }" style="padding:0px!important; margin:0px!important;">
                {{item.created_at.slice(0, 10)}}
            </template>

            <!-- Empresa -->
            <template v-show="item.company!=false" v-slot:[`item.company`]="{ item }">
                <v-list-item class="px-0" style="min-height:0px!important; font-size:14px;" :to="{ path: '/clients/client/'+ item.companyID}">{{item.company.slice(0, 10)}}</v-list-item>
            </template>

            <!-- Actualización -->
            <template v-slot:[`item.updated_at`]="{ item }">
                {{item.updated_at.slice(0, 10)}}
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de actividades aún
            </template>
        </v-data-table>
        <!-- Crear actividad -->
        <v-dialog v-model="createDialog" max-width="700px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#e74919" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <create @closeCreateDialogCalendar="closeCreateDialogCalendar"/>
        </v-dialog>
        <!-- Editar actividad -->
        <v-dialog v-model="editDialog" max-width="700px">
          <edit v-bind:calendar="calendar" @closeDialogEditCalendar="closeDialogEditCalendar"/>
        </v-dialog>
        <!-- Dialogo confirmación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar esta actividad?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteCalendar()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
    </v-container>
</template>

<script>
import axios from "axios";
import XLSX from 'xlsx';
import Filter from "../activities/filter"
import Create from "../activities/create"
import Edit from "../activities/edit"
export default {
    props:{
        company:String
    },
    components: {
        'filterClients':Filter,
        'create':Create,
        'edit':Edit,
    }, 
    data: () => ({
        calendar:'',
        sheet: false,
        filters: false,
        editDialog: false,
        createDialog: false,
        detailDialog:false,
        deleteId:'',
        calendars: ''
    }),
    computed: {
        currentUser:{
            get(){
                return this.$store.state.currentUser.user;
            }
        },
        showCompany(){
            if(this.company == null){
                return { text: 'Empresa', value: 'company',}
            }else{
                return false
            }
        },
        headers(){ 
            this.calendars = this.calendarsLists
            return [
            { text: 'Fecha', value: 'date' },
            this.showCompany,
            { text: 'Contacto', value: 'contact' },
            { text: 'Actividad', value: 'activity' },
            { text: 'Descripción', value: 'description' },
            { text: 'Estado', value: 'completed', sortable: false },
            { text: 'Ejecutivo', value: 'salesman', sortable: false },
            { text: 'Creación', value: 'created_at' },
            { text: 'Edición', value: 'updated_at' },
            { value: 'actions', sortable: false, align: 'end', },
        ]},
        calendarsLists(){
            var calendars = this.$store.state.calendar.calendars
            if(this.company!='' && this.company!=undefined && this.company!=null){
                calendars = calendars.filter(calendar=>calendar.company_id == this.company)
            }
            calendars = calendars.map(id=>{
                return{
                    id:id.id,
                    companyID:id.company_id,
                    company:this.companyName(id.company_id),
                    contact:this.contact(id.contact_id),
                    activity:this.activity(id.activity_id),
                    salesman:this.salesman(id.company_id),
                    date:id.date,
                    description:id.description,
                    completed:id.completed,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });
            return calendars
        },
    },
    created () {
        this.$store.dispatch('currentUser/getUser')
        this.$store.dispatch('company/getCompanies')
        this.$store.dispatch('contact/getContacts')
        this.$store.dispatch('calendar/getCalendars')
        this.$store.dispatch('activity/getActivities')
        this.$store.dispatch('user/getUsers')  
        this.calendars = this.calendarsLists   
    },
    methods: {
        permissions(permission){
            if(this.currentUser.permissions!=undefined){
                if(this.currentUser.permissions.includes(permission)){
                    return true
                }else{
                    return false
                }
            }else if(this.currentUser.id==1){
                return true
            }else{
                return false
            }
            
        },
        status(id, status){
            var editedItem = this.calendarsLists.filter(calendar=>calendar.id == id)[0]
            editedItem.completed = status
            axios.put("https://" + localStorage.getItem("tenant") + ".universidaddeventas.uno/calendar/update",Object.assign(editedItem)).then(response=>{
                this.$store.dispatch('calendar/getCalendars')
            })
        },
        removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject  = {};
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        },
        filtersCalendar: function(params) {
            this.calendars = ''
            var filterCalendar = this.calendarsLists
            if(params.activity_id!=''&&params.activity_id!=undefined&&params.activity_id!=null){
                var cuatro = filterCalendar.filter(company=>company.activity_id == params.activity_id[0])
                for(var i=1; i<params.activity_id.length; i++){
                    cuatro=cuatro.concat(filterCalendar.filter(company=>company.activity_id == params.activity_id[i]))   
                }
                filterCalendar = this.removeDuplicates(cuatro, "id");
            }
            if(params.company_id!=''&&params.company_id!=undefined&&params.company_id!=null){
                var cuatro = filterCalendar.filter(company=>company.company_id == params.company_id[0])
                for(var i=1; i<params.company_id.length; i++){
                    cuatro=cuatro.concat(filterCalendar.filter(company=>company.company_id == params.company_id[i]))   
                }
                filterCalendar = this.removeDuplicates(cuatro, "id");
            }
            if(params.contact_id!=''&&params.contact_id!=undefined&&params.contact_id!=null){
                var cuatro = filterCalendar.filter(company=>company.contact_id == params.contact_id[0])
                for(var i=1; i<params.contact_id.length; i++){
                    cuatro=cuatro.concat(filterCalendar.filter(company=>company.contact_id == params.contact_id[i]))   
                }
                filterCalendar = this.removeDuplicates(cuatro, "id");
            }
            if(params.user_id!=''&&params.user_id!=undefined&&params.user_id!=null){
                var cuatro = filterCalendar.filter(company=>company.user_id == params.user_id[0])
                for(var i=1; i<params.user_id.length; i++){
                    cuatro=cuatro.concat(filterCalendar.filter(company=>company.user_id == params.user_id[i]))   
                }
                filterCalendar = this.removeDuplicates(cuatro, "id");
            }
            /* text fields */
            if(params.description!=''){
                filterCalendar=filterCalendar.filter(calendar=>calendar.description.toLowerCase().includes(params.description.toLowerCase()))
            }
            /* Fecha */
            if(params.dateFrom!='' && params.dateFrom!=null && params.dateFrom!=undefined){
                filterCalendar=filterCalendar.filter(calendar=>new Date(calendar.date) > new Date(params.dateFrom))
            }
            if(params.dateTo!='' && params.dateTo!=null && params.dateTo!=undefined){
                var dateTwo = new Date(new Date(params.dateTo).setDate(new Date(params.dateTo).getDate() + 1))
                filterCalendar=filterCalendar.filter(calendar=>new Date(calendar.date) <= dateTwo)
            }
            this.calendars = filterCalendar.map(id=>{
                return{
                    id:id.id,
                    company:this.companyName(id.company_id),
                    contact:this.contact(id.contact_id),
                    activity:this.activity(id.activity_id),
                    salesman:this.salesman(id.company_id),
                    date:id.date,
                    description:id.description,
                    completed:id.completed,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });
        },
        closeDialogEditCalendar: function(params) {
            this.editDialog = false;
            this.$store.dispatch('calendar/getCalendars')
        },
        closeCreateDialogCalendar: function(params) {
            this.createDialog = false;
            this.$store.dispatch('calendar/getCalendars')
        },
        exportExcel: function () {
            let data = XLSX.utils.json_to_sheet(this.calendars)
            const workbook = XLSX.utils.book_new()
            const filename = 'Lista de Actividades'
            XLSX.utils.book_append_sheet(workbook, data, filename)
            XLSX.writeFile(workbook, `${filename}.xlsx`)
        },
        color(id){
            return this.$store.state.activity.activities.filter(activity=>activity.id == id).map(activity => activity.color)[0]
        },
        deleteCalendar(){
            axios.delete("https://" + localStorage.getItem("tenant") + ".universidaddeventas.uno/calendar/delete/"+this.deleteId).then(response => {
                this.deleteId = ''
                this.sheet = false
                this.$store.dispatch('calendar/getCalendars')
            });
        },
        cancel(){
            this.deleteId = ''
            this.sheet = false
        },
        deleteItem (item) {
            this.deleteId = item.id
            this.sheet = true
        },
        editItem(item){
            this.calendar = this.calendarsLists.filter(calendar=>calendar.id == item.id)[0]
            this.editDialog = true
        },
        salesman(company_id){
            var user_id = this.$store.state.company.companies.filter(company=>company.id == company_id).map(company => company.user_id)[0]
            return this.$store.state.user.users.filter(user=>user.id == user_id).map(user => user.name)[0]
        },
        activity(id){
            return this.$store.state.activity.activities.filter(activity=>activity.id == id).map(activity => activity.type)[0]
        },
        color(type){
            return this.$store.state.activity.activities.filter(activity=>activity.type == type).map(activity => activity.color)[0]
        },
        contact(id){
            return this.$store.state.contact.contacts.filter(contact=>contact.id == id).map(contact => contact.name + ' ' + contact.last)[0]
        },
        companyName(id){
            return this.$store.state.company.companies.filter(company=>company.id == id).map(company => company.name)[0]
        },
    }
}
</script>


<style>
    .bordercolor{
        width: 3px;
        content: ' ';
        height: 47px;
        position: absolute;
        border-radius:5px 0px 0px 5px;
    }
    @media(min-width: 980px){
        .bordercolor{
            margin-top: -13px;
            margin-left: -19px;
        }
    }
    @media(max-width: 980px){
        .bordercolor {
            height: 360px;
            left: 9px;
        }
    }
</style>